import axios from 'axios';
import React, { useState, useEffect, useCallback } from 'react';
import { localapi } from '../Assets/config';
import Card from './Card';
import useMeasure from 'react-use-measure';
import { motion, useAnimation, useMotionValue } from 'framer-motion';
import { DNA } from 'react-loader-spinner';

const InfiniteMoving = ({ home }) => {
    const [ref, { width }] = useMeasure();
    const [data, setData] = useState([]);
    const [isHovered, setIsHovered] = useState(false);
    const [xPosition, setXPosition] = useState(0); // Track current x position

    const xTranslation = useMotionValue(0);
    const controls = useAnimation();  // Use Framer Motion's animation controls

    const fullWidth = width ? width / 2 + 4 : 0; // Calculate full width for animation

    // Function to fetch data
    const fetchData = useCallback(async () => {
        try {
            const res = await axios.get(`${localapi}/banner`);
            setData(res.data.data);
        } catch (error) {
            console.log(error);
        }
    }, []);

    // Start animation function with dynamic duration
    const startAnimation = useCallback(
        (remainingDuration) => {
            const remainingDistance = -fullWidth - xPosition; // Calculate remaining distance
            const calculatedDuration = remainingDuration || (60 * remainingDistance) / -fullWidth; // Calculate proportional duration

            controls.start({
                x: -fullWidth,
                transition: {
                    ease: 'linear',
                    duration: calculatedDuration,
                    repeat: Infinity,
                    repeatType: 'loop',
                },
            });
        },
        [controls, fullWidth, xPosition]
    );

   
    const handleMouseEnter = () => {
        setIsHovered(true);
        controls.stop();  // Pause animation
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
        startAnimation();  // Resume animation from the current position
    };

    // Fetch data on mount
    useEffect(() => {
        fetchData();
    }, [fetchData]);

    // Start animation on mount or when width updates
    useEffect(() => {
        if (!isHovered && fullWidth > 0) {
            startAnimation();
        }
    }, [startAnimation, isHovered, fullWidth]);

    // Track x position
    useEffect(() => {
        xTranslation.onChange((latestX) => {
            setXPosition(latestX); // Update current x position
        });
    }, [xTranslation]);

    return (
        <div className={`${home ? 'flex' : 'hidden'} overflow-hidden w-[100vw]`}>
            {data.length > 0 ? (
                <motion.div
                    ref={ref}
                    className='absolute bg-purple-950 left-0 flex flex-row gap-2 scrollbar-hide mt-[-0.8vw] md:mt-[-1.38vw]'
                    animate={controls} // Attach the animation controls
                    style={{ x: xTranslation }} // Apply x translation
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    {[...data, ...data].map((item, key) => (
                        <div key={key}>
                            <Card item={item} />
                        </div>
                    ))}
                </motion.div>
            ) : (
                <div className='absolute text-[4vw] flex justify-center md:text-[1vw] w-[100%] text-center items-center text-white bg-purple-950 left-0 flex flex-row gap-2 scrollbar-hide mt-[-0.8vw] md:mt-[-1.38vw]'>
                    <DNA visible={true} height="60" width="60" ariaLabel="dna-loading" />
                </div>
            )}
        </div>
    );
};

export default InfiniteMoving;
